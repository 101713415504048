
import Vue from 'vue'
import MyPageSubscribe from '../../views/mypage/MyPageSubscribe.vue'
import MyPageDibsProduct from '../../views/mypage/MyPageDibsProduct.vue'
import Footer from '../../common/layout/Footer.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { AppProfile } from '../../../../types/App'
import MyPageSquareButton from '@/components/views/mypage/MyPageSquareButton.vue'
import MyPageTextButton from '@/components/views/mypage/MyPageTextButton.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'MyPageVue',
  components: {
    MyPageTextButton,
    MyPageSquareButton,
    MyPageSubscribe,
    MyPageDibsProduct,
    Footer,
  },
  props: {
    checkInNo: {
      type: String,
      default: '',
    },
    showNbadge: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('MyPageStore', ['getUserProfile', 'getLastNoticeDate']),
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapGetters('ProfileStore', ['getProfile']),
    ...mapState('MyPageStore', ['onSkeleton']),
  },
  async created() {
    await this.fetchUserProfile()
  },
  methods: {
    ...mapActions('HomeStore', ['fetchInit']),
    ...mapActions('UserStore', ['fetchUserProfile']),
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    ...mapActions('CommonStore', ['fetchHeaderColor']),
    goNoticePage() {
      if (this.getLastNoticeDate) {
        localStorage.setItem('noticeReadDate', this.getLastNoticeDate)
      }

      try {
        const url = this.createNoticeUrl()
        const payload = {
          url,
          title: '공지사항',
          isGoneBottomView: true,
        }
        console.log('check url', url)
        localStorage.setItem('IS_VIEW_NOTICE', 'true')
        this.$tracking('mypage_notice')
        if (!this.deviceInfo) return this.$router.push('mypageBoard')
        this.$rendingOutsidePage(this.$device, payload)
        this.$emit('isNoitceRead')
      } catch (e) {
        console.log('myPage subWebView err')
      }
    },
    goOrder() {
      const url = 'https://new-m.pay.naver.com/historybenefit/paymenthistory'
      const payload = {
        url,
        title: '주문내역',
      }
      this.$tracking('mypage_order')
      this.$rendingOutsidePage(this.$device, payload)
    },
    goUserInfo() {
      this.$tracking('mypage_info')
      this.$router.push('/mypage/info')
    },
    onSettingPage() {
      this.$tracking('mypage_setting')
      this.$router.push('/mypage/setting')
    },
    goCart() {
      const url = 'https://m.shopping.naver.com/cart'
      const payload = {
        url: url,
        title: '장바구니',
      }
      this.$tracking('mypage_cart')
      this.$rendingOutsidePage(this.$device, payload)
    },
    goShippingList() {
      const url = 'https://new-m.pay.naver.com/member/shipping/list'
      const payload = {
        url: url,
        title: '배송지 목록',
      }

      this.$tracking('mypage_address')
      this.$rendingOutsidePage(this.$device, payload)
    },
    goKakaoPage() {
      const url = this.getProfile.urls.chatbot
      const payload = {
        url,
        isGoneBottomView: true,
      }
      this.$tracking('mypage_qa')
      this.$rendingOutsidePage(this.$device, payload)
    },
    createNoticeUrl() {
      const envPrefix = process.env.VUE_APP_ENV === 'production' ? '' : '-alpha'
      const appSuffix = this.deviceInfo !== null ? '?mobileApp=true' : ''
      return `https://intro${envPrefix}.cellook.kr/notice${appSuffix}&referrer=cellook`
    },
    showLoginDialog() {
      this.$tracking('mypage_login')
      this.fetchLoginDialog({ yn: true })
    },
    async onIntersect(entries: IntersectionObserverEntry[]) {
      // const color = entries[0].intersectionRatio < 1 ? 'default' : 'blue'
      await this.fetchHeaderColor('#ffffff')
    },
    goNaverCoupon() {
      const url = 'https://m.pay.naver.com/b/coupon'
      const payload = {
        url: url,
        title: '네이버 페이 쿠폰 내역',
      }

      this.$tracking('mypage_npaycoupon')
      this.$rendingOutsidePage(this.$device, payload)
    },
    goCheckInPage() {
      this.$tracking('mypage_checkin')
      this.$router.push({
        name: 'CheckIn',
        query: { no: this.checkInNo },
      })
    },
  },
})
interface Data {}
interface Computed {
  getUserProfile: UserProfileGroup
  deviceInfo: DeviceInfo | null
  getProfile: AppProfile
  getLastNoticeDate: string | null
}
interface Methods {
  goKakaoPage(): void
  goOrder(): void
  goNoticePage(): void
  fetchInit(): void
  createNoticeUrl(): string
  fetchUserProfile: () => Promise<void>
  fetchLoginDialog: (payload: { yn: boolean }) => void
  showLoginDialog(): void
  goCart(): void
  goShippingList(): void
  onIntersect(entries: IntersectionObserverEntry[]): void
  fetchHeaderColor: (color: string) => void
  goUserInfo(): void
  onSettingPage(): void
  goNaverCoupon(): void
  goCheckInPage(): void
}
interface Props {
  checkInNo: string
  showNbadge: boolean
}
