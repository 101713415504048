
import Vue from 'vue'

export default Vue.extend({
  name: 'MyPageTextButton',
  props: {
    title: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
})
