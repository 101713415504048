
import Vue from 'vue'

export default Vue.extend({
  name: 'MyPageSquareButton',
  props: {
    iconPath: {
      type: String,
      required: true,
    },
    buttonName: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: '106px',
    },
  },
})
