var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-14"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"d-flex font-g16-18 gray900--text rixgo-bold ml-10 mr-auto"},[_c('div',[_vm._v("구독 몰")]),(_vm.getShopItemGroup.count > 0 && _vm.isLogin)?_c('span',{staticClass:"font-g14-20 rixgo-bold gray500--text ml-4"},[_vm._v(_vm._s(_vm._f("handleTransform")(_vm.getShopItemGroup.count)))]):_vm._e()]),(_vm.getShopItemGroup.count === 0 && _vm.isLogin)?_c('div',{staticClass:"d-flex align-center ml-auto pr-10 font-g12-18 gray500--text rixgo-bold"},[_c('div',{staticClass:"pr-2"},[_c('v-img',{attrs:{"src":require("../../../assets/images/Bang.svg")}})],1),_c('div',{staticClass:"pt-1"},[_vm._v("구독한 몰이 없어요. 인기몰을 추천해드릴게요.")])]):_vm._e(),(_vm.getShopItemGroup.count > 0 && _vm.isLogin)?_c('img',{staticClass:"ml-auto mr-10",staticStyle:{"height":"16px","width":"16px"},attrs:{"src":require('@/assets/images/arrowNextGray.svg')},on:{"click":function($event){return _vm.goSubs()}}}):_vm._e()]),_c('div',{staticClass:"pt-8 pb-11"},[_c('div',{staticClass:"d-flex ml-10"},_vm._l((9),function(count){return _c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.onSkeleton),expression:"onSkeleton"}],key:count,staticClass:"ma-1 mb-10",attrs:{"type":"avatar","min-height":"60","min-width":"60"}})}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.onSkeleton && _vm.isLogin),expression:"!onSkeleton && isLogin"}]},[(
          _vm.getShopItemGroup.newShopItemList.length > 0 ||
          _vm.getShopItemGroup.recommendShopItemList.length > 0
        )?_c('Slider',{attrs:{"sliderInName":"mall","swipeClass":"max-width","indexKey":"shopId","activeKey":"shopId","itemClass":"border-none mx-1","firstSlot":_vm.getShopItemGroup.count === 0,"lastSlot":true,"list2":_vm.getShopItemGroup.newShopItemList,"list":_vm.getShopItemGroup.recommendShopItemList,"differentItemClass":['pl-4', '', ''],"newMallProps":{
          badge: true,
          badgeIcon: '$vuetify.icons.mallNewIcon',
          borderStyleText: `border-width: 0;border-radius: 24px`,
        },"mallProps":{
          badge: true,
          imageContent: 'Recommend',
          borderStyleText: `border-width: 0;border-radius: 24px`,
        },"badgeToggleKey":"isSubs","badgeIcons":[
          '$vuetify.icons.outlineCheckIcon',
          '$vuetify.icons.addIcon',
        ],"onVertical":false},on:{"handleActive":_vm.goHome,"badgeClick":_vm.toggleSubscribe}},[_c('div',{staticStyle:{"width":"20px"},attrs:{"slot":"last"},slot:"last"}),_c('div',{staticClass:"pl-6 mr-3",attrs:{"slot":"first"},on:{"click":function($event){return _vm.goStoreTab()}},slot:"first"},[_c('div',{staticClass:"background-gradient-blue",staticStyle:{"width":"60px","height":"60px","border-radius":"24px"}},[_c('div',{staticClass:"font-g12-13 relative text-center transform-50 top-50 left-50 pt-1 white--text"},[_c('div',{staticClass:"font-g12-16 rixgo-bold"},[_vm._v("전체몰")]),_c('div',{staticClass:"font-g12-16 rixgo-regular"},[_vm._v("보기")])])])])]):_vm._e()],1),(!_vm.onSkeleton && !_vm.isLogin)?_c('div',{staticClass:"fill-width px-10",on:{"click":function($event){return _vm.showLoginDialog()}}},[_c('div',{staticClass:"fill-width d-inline-flex align-center justify-center",staticStyle:{"height":"68px","border-radius":"10px","background-color":"#f7f8f9"}},[_c('img',{attrs:{"src":require('@/assets/images/roundPlus.svg')}}),_vm._m(0)])]):_vm._e()]),_c('Alert',{attrs:{"show":_vm.alert.show,"content":_vm.alert.message},on:{"onClose":function($event){_vm.alert = { show: false, message: '' }}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ml-4"},[_c('span',{staticClass:"font-g12-18 rixgo-bold gray900--text"},[_vm._v("로그인")]),_c('span',{staticClass:"font-g12-18 gray900--text"},[_vm._v("하고 마음에 드는 몰을 구독하세요.")])])
}]

export { render, staticRenderFns }