
import Vue from 'vue'
import MyPageView from '../../views/mypage/MyPage.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default Vue.extend<Data, Methods, Computed>({
  name: 'MyPage',
  components: { MyPageView },
  computed: {
    ...mapState('CheckInStore', ['no']),
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapGetters('ProfileStore', ['getProfile']),
    ...mapGetters('MyPageStore', ['getLastNoticeDate']),
  },
  watch: {
    isLogined: {
      immediate: true,
      async handler() {
        this.init()
      },
    },
  },
  data() {
    return {
      showNbadge: false,
    }
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('MyPageStore', [
      'asyncGetMyPage',
      'asyncGetSettings',
      'fetchLastNoticeDate',
      'fetchOnSkeleton',
    ]),
    ...mapActions('DibsStore', ['asyncGetDibsSubscribe']),
    async init() {
      try {
        this.fetchLoading({ yn: true })
        this.fetchOnSkeleton(true)

        if (this.isLogined) {
          await this.asyncGetMyPage()
          this.asyncGetDibsSubscribe()
        }

        if (this.deviceInfo) {
          this.asyncGetSettings()
        }

        await this.fetchLastNoticeDate()

        if (!this.getLastNoticeDate) {
          this.showNbadge = false
          return
        }

        if (
          localStorage.getItem('noticeReadDate') &&
          this.$moment(localStorage.getItem('noticeReadDate')).isSameOrAfter(
            this.getLastNoticeDate
          )
        ) {
          this.showNbadge = false
        } else {
          this.showNbadge = true
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.fetchLoading({ yn: false })
        this.fetchOnSkeleton(false)
      }
    },
    async handleRefresh() {
      this.init()
    },
  },
})

interface Data {
  showNbadge: boolean
}
interface Computed {
  no: string
  deviceInfo: DeviceInfo
  getLastNoticeDate: string | null
}
interface Methods {
  init: () => void
  handleRefresh: () => void
  fetchLoading: (payload: { yn: boolean }) => Promise<void>
  fetchOnSkeleton: (payload: boolean) => void
  fetchLastNoticeDate: () => Promise<void>
  asyncGetMyPage: () => Promise<void>
  asyncGetDibsSubscribe: () => Promise<void>
  asyncGetSettings: () => Promise<void>
}
