
import Vue from 'vue'
import { DeleteSubscribe, PostSubscribe } from '@/api/displayAPI/UserAPI'
import { mapActions, mapGetters, mapState } from 'vuex'
import Slider from '../../common/slider/Slider.vue'
import Alert from '../../common/dialogs/Alert.vue'

export default Vue.extend({
  components: {
    Slider,
    Alert,
  },
  props: {
    isLogin: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    tab: 0,
    active: 0,
    shopId: '',
    shopInfo: {},
    alert: {
      show: false,
      message: '',
    },
  }),
  computed: {
    ...mapGetters('MyPageStore', ['getShopItemGroup']),
    ...mapState('MyPageStore', ['onSkeleton']),
  },
  methods: {
    ...mapActions('HomeStore', ['fetchMainTab']),
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    async subscribe(subShopId: string, idx: number) {
      if (!subShopId) return
      try {
        const { recommendShopItemList } = this.getShopItemGroup
        await PostSubscribe(subShopId, recommendShopItemList[idx].shopName)
        this.getShopItemGroup.count += 1
        recommendShopItemList[idx].isSubs = true
      } catch (e: any) {
        if (e && e.error_code === 400) {
          this.alert = { show: true, message: e.message }
        }
      }
    },
    async unSubscribe(subShopId: string, idx: number) {
      if (!subShopId) return
      try {
        const { recommendShopItemList } = this.getShopItemGroup
        await DeleteSubscribe(subShopId, recommendShopItemList[idx].shopName)
        this.getShopItemGroup.count -= 1
        recommendShopItemList[idx].isSubs = false
      } catch (e) {
        console.log(e)
      }
    },
    async toggleSubscribe(item: ShopItem, idx: number) {
      //기본값 없을때 false로 설정
      const { recommendShopItemList } = this.getShopItemGroup
      if (!recommendShopItemList[idx].isSubs) {
        recommendShopItemList[idx].isSubs = false
      }
      // 구독/구독취소 API 호출
      try {
        if (recommendShopItemList[idx].isSubs) {
          await this.unSubscribe(item.shopId, idx)
        } else {
          await this.subscribe(item.shopId, idx)
        }
      } catch (e) {
        console.log(e)
      }
    },
    goSubs() {
      this.$tracking('mypage_mymall_all')
      this.$router.push({ name: 'Dibs', query: { acitve: 'subs' } })
    },
    goStoreTab() {
      this.fetchMainTab({ tab: 1 })
      this.$router.push('/')
    },
    goHome(item: ShopItem, index: number) {
      if (!item.shopId) return
      this.$tracking(`mypage_mymall_${this.$numbering(index + 1)}`)
      const shopId = item.shopId
      this.$router.push({ name: 'StoreHome', query: { shopId } })
    },
    showLoginDialog() {
      this.$tracking('mypage_login')
      this.fetchLoginDialog({ yn: true })
    },
  },
})
