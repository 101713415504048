
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Slider from '../../common/slider/Slider.vue'
import CellookButton from '@/components/common/buttons/CellookButton.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CellookButton, Slider },
  props: {
    isLogin: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    ...mapState('MyPageStore', ['onSkeleton']),
    ...mapGetters('MyPageStore', ['getLikeItemGroup']),
    isAvailableFalse() {
      let findAvailable = this.getLikeItemGroup.searchProductList
      let isAvailableCnt = 0
      for (let key in findAvailable) {
        if (!findAvailable[key].isAvailable) {
          isAvailableCnt += 1
        }
      }
      return isAvailableCnt
    },
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    goDibs() {
      this.$tracking('mypage_like_all')
      this.$router.push({ name: 'Dibs', query: { acitve: 'dibs' } })
    },
    goTotal() {
      this.$router.push({
        name: 'CategoryBestList',
        query: {
          categoryName: 'Best Seller',
        },
      })
    },
    goStoreHome(item: ProductItem, index: number) {
      this.$tracking(`mypage_like_${this.$numbering(index + 1)}`)
      this.$rendingProduct(item.productNo)
    },
    showLoginDialog() {
      this.$tracking('mypage_login')
      this.fetchLoginDialog({ yn: true })
    },
  },
})

interface Data {}
interface Methods {
  goDibs(): void
  goTotal(): void
  goStoreHome(item: ProductItem, index: number): void
  showLoginDialog(): void
  fetchLoginDialog: (payload: { yn: boolean }) => Promise<void>
}
interface Computed {
  isAvailableFalse: number
  getLikeItemGroup: any
  onSkeleton: boolean
}
interface Props {}
