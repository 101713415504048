
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

export default Vue.extend({
  name: 'Footer',
  computed: {
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapGetters('ProfileStore', ['getProfile']),
  },
  data() {
    return {
      count: 0,
    }
  },
  methods: {
    async goPage(data: string) {
      switch (data) {
        case '/footerTerms':
          this.$tracking('home_footer_04')
          this.subWebView(
            `https://intro${
              process.env.VUE_APP_ENV === 'production' ? '' : '-alpha'
            }.cellook.kr/terms`,
            '이용약관',
            data
          )
          break
        case '/footerPrivacy':
          this.$tracking('home_footer_05')
          this.subWebView(
            `https://intro${
              process.env.VUE_APP_ENV === 'production' ? '' : '-alpha'
            }.cellook.kr/privacy`,
            '개인정보처리방침',
            data
          )
          break
        case '/footerRecruit':
          this.$tracking('home_footer_06')
          if (this.$device.isIOS && this.deviceInfo !== null) {
            window.webkit?.messageHandlers?.sendExternalWebBrowser?.postMessage(
              'https://cellook.career.greetinghr.com/'
            )
            return
          }
          if (this.$device.isAndroid && this.deviceInfo !== null) {
            window.CellookBridge?.sendExternalWebBrowser(
              'https://cellook.career.greetinghr.com/'
            )
            return
          }
          window.open('https://cellook.career.greetinghr.com/', '_blank')
          break
      }
    },
    //TODO : 추후 모듈화
    subWebView(url: string, title: string, data: string) {
      const payload = {
        url,
        title,
        isGoneBottomView: true,
      }

      if (this.deviceInfo == null) {
        this.$router.push(data)
        return
      }
      this.$openSubWebView(payload)
    },
    goNewPage(data: string) {
      this.$tracking('home_footer_08')
      if (this.$device.isIOS && this.deviceInfo !== null) {
        window.webkit.messageHandlers.sendExternalWebBrowser.postMessage(data)
        return
      }
      if (this.$device.isAndroid && this.deviceInfo !== null) {
        window.CellookBridge?.sendExternalWebBrowser(data)
        return
      }
      window.open(data, '_blank')
    },
    goContact() {
      this.$tracking('home_footer_07')
      const url = this.getProfile.urls.chatbot
      const payload = {
        url,
        isGoneBottomView: true,
      }
      if (this.deviceInfo == null) {
        window.open(url, '_blank')
        return
      }
      this.$openSubWebView(payload)
    },
    goPromotionPage(selectedUrl: any) {
      let url = ''
      let trackingNumber = 0

      switch (selectedUrl) {
        case 'instagram':
          url = 'https://www.instagram.com/cellook.kr'
          trackingNumber = 1
          break

        case 'kakaochannel':
          url = 'https://pf.kakao.com/_IBLbK/friend'
          trackingNumber = 2
          break

        case 'naverblog':
          url = 'https://blog.naver.com/cellook_kr'
          trackingNumber = 3
          break
      }
      this.$tracking(`home_footer_0${trackingNumber}`)

      if (this.$device.isIOS && this.deviceInfo !== null) {
        window.webkit?.messageHandlers?.sendExternalWebBrowser?.postMessage(url)
        return
      }
      if (this.$device.isAndroid && this.deviceInfo !== null) {
        window.CellookBridge?.sendExternalWebBrowser(url)
        return
      }

      window.open(url, '_blank')
    },
    cellookInstallPage() {
      this.$tracking('home_footer_04')
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.search('android') > -1) {
        window.location.href =
          'https://play.google.com/store/apps/details?id=kr.co.greenandgrey.cellook'
      }

      if (
        userAgent.search('iphone') > -1 ||
        userAgent.search('ipod') > -1 ||
        userAgent.search('ipad') > -1
      ) {
        window.location.href = 'https://itunes.apple.com/app/id1614348585'
      }
    },
    versionCheck() {
      this.count++

      if (this.count > 10) {
        this.count = 0
      }
    },
    logout() {
      try {
        this.$logout()
        this.$tracking('home_footer_logout')
      } catch (error) {
        console.log(error)
      }
    },
  },
})
