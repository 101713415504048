import { render, staticRenderFns } from "./MyPageSquareButton.vue?vue&type=template&id=37c6bac2&scoped=true&"
import script from "./MyPageSquareButton.vue?vue&type=script&lang=ts&"
export * from "./MyPageSquareButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37c6bac2",
  null
  
)

export default component.exports